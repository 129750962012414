.hp-userAnnouncements {
  display: none;
}
@media only screen and (max-width: 768px) and (orientation: portrait) {
  .hp-homepage-notconnected {
    display: none !important;
  }
  body {
    overflow-y: auto !important;
    position: relative !important;
  }
  .hp-userAnnouncements {
    display: flex;
  }
}

@media only screen and (max-width: 900px) and (orientation: landscape) {
  .hp-homepage-notconnected {
    display: none !important;
  }
  .hp-userAnnouncements {
    display: flex;
  }
  body {
    overflow-y: auto !important;
    position: relative !important;
  }
}

.hp-userAnnouncements {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba($color: black, $alpha: 0.95);
  z-index: 100;
  align-items: center;
  flex-direction: column;
  .hp-userAnnouncements-wrapper {
    max-width: 500px;
    width: 100%;
    height: auto;
    position: relative;
  }
  svg {
    width: 100%;
    height: auto;
    max-width: 500px;
  }
  .hp-userAnnouncements-header {
    position: fixed;
  }
  .hp-userAnnouncements-content {
    margin-top: calc(min(30vw, 150px));
  }
  .hp-userAnnouncements-btn {
    position: absolute;
    right: calc(min(5vw, 25px));
    bottom: calc(min(30vw, 150px));
    &, svg {
      width: calc(min(45vw, 225px));
    }
    svg {
      height: auto;
    }
    .hp-userAnnouncements-btn-android {
      margin-bottom: calc(min(5vw, 25px));
    }
  }
}