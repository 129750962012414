@import '../../common.scss';

.payment-layout.ks-embedded.hp-v3 {
  height: 100%;
}

.hp-checkoutWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  .card-element-errors {
    color: #CB5445;
    font-weight: 500;
    text-align: center;
    font-family: 'Avenir';
    width: calc(100% - 2px);
    background-color: #F5E2DF;
    border: 1px solid #CB5445;
    font-size: responsiveSizeToV2(18);
    height: responsiveSizeToV2(25);
    &.empty {
      border: 1px solid transparent;
      background-color: transparent;
    }
  }
  .hp-checkoutPricing {
    width: 100%;
    display: flex;
    font-weight: 100;
    position: relative;
    font-family: 'Avenir';
    align-items: center;
    justify-content: center;
    background-color: #EEF2F4;
    height: responsiveSizeToV2(91);
    font-size: responsiveSizeToV2(34);
    svg {
      position: absolute;
      margin-left: responsiveSizeToV2(550);
      width: responsiveSizeToV2(157);
      height: responsiveSizeToV2(45);
    }
  }
  .hp-checkoutForm {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: responsiveSizeToV2(540);
    height: responsiveSizeToV2(511);
    margin-top: responsiveSizeToV2(5);
    color: #A3A8AA;
    font-weight: 300;
    font-family: 'Avenir';
    font-size: responsiveSizeToV2(18);
    label {
      display: flex;
      position: relative;
      flex-direction: column;
      span { margin-left: responsiveSizeToV2(10); }
      .field {
        display: flex;
        align-items: center;
        .inputForm {
          width: 100%;
          display: flex;
          align-items: center;
          font-family: 'Avenir';
          border: responsiveSizeToV2(2) solid #CCD1D3;
          height: responsiveSizeToV2(60);
          font-size: responsiveSizeToV2(20);
          border-radius: responsiveSizeToV2(10);
          padding-left: responsiveSizeToV2(70);
          &::placeholder {
            font-weight: 400;
            color: #aab7c4;
          }
          &:focus-within,
          &.StripeElement--focus {
            outline: none !important;
            border-color: #51AABC;
          }
          .__PrivateStripeElement {
            width: 100%;
            height: fit-content;
          }
          &.StripeElement--invalid {
            z-index: 10;
            color: #BF594C;
            border-color: #BF594C;
            background-color: #F5E2DF;
          }
        }
        svg.fieldIcon {
          width: auto;
          z-index: 11;
          position: absolute;
          left: responsiveSizeToV2(20);
        }
        svg.cb,
        svg.email {
          height: responsiveSizeToV2(31);
        }
        svg.cvc,
        svg.calendar {
          height: responsiveSizeToV2(34);
        }
        input {border: none;outline: none !important;}
        svg.warning-icon {
          position: absolute;
          display: none;
        }
        &.invalid {
          svg.fieldIcon {
            path { fill: #BF594C }
          }
          svg.warning-icon {
            z-index: 11;
            display: block;
            position: absolute;
            right: responsiveSizeToV2(16);
            width: responsiveSizeToV2(39);
            height: responsiveSizeToV2(35);
          }
        }
      }
    }
    .grouped-field {
      display: flex;
      .left .field .inputForm {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
      .right .field .inputForm {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
      label { 
        width: 50%; 
        &.left {
          width: calc(50% + responsiveSizeToV2(2));
          .StripeElement--focus {
            z-index: 10;
          }
        }
        &.right {
          margin-left: responsiveSizeToV2(-2);
          .StripeElement--focus {
            z-index: 10;
          }
        }
      }
    }

    label.checkbox {
      align-items: center;
      flex-direction: row;
      .checkbox__input, .checkbox__control {
        margin-left: responsiveSizeToV2(2);
      }
    }
    button {
      border: none;
      color: white;
      background-color: #51AABC;
      height: responsiveSizeToV2(60);
      font-size: responsiveSizeToV2(25);
      border-radius: responsiveSizeToV2(10);
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .stripe-logo {
      width: 100%;
      display: flex;
      color: #484848;
      align-items: center;
      justify-content: center;
      font-size: responsiveSizeToV2(18);
      svg {
        height: responsiveSizeToV2(27);
      }
    }
  }
}