@import '../../common.scss';

.hp-layoutV2 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  height: var(--mainContentHeight);
  max-width: var(--vw);
  position: relative;
  &.hp-v3 .ks-content {
    background-color: #323334;
  }
  @at-root #app-platform {
    height: var(--vmin);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .hp-topBanner {
    display: none;
  }
  .hp-clipLoader {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      position: relative !important;
    }
  }
  .hp-headerArea {
    top: 0;
    left: 0;
    z-index: 10;
    position: absolute;
    height: var(--headerHeight);
    width: var(--leftAreaMinWidth);
    display: flex;
    justify-content: center;
  }
  .hp-leftArea {
    flex: 1;
    min-width: var(--leftAreaMinWidth);
    height: calc(var(--mainContentHeight));
    display: flex;
    align-items: center;
    position: relative;
    .hp-freemium-banner {
      width: 100%;
      z-index: 5;
      display: flex;
      position: absolute;
      flex-direction: column;
      align-items: center;
      top: responsiveSizeLegacyToV2(115);
      .hp-freemium-banner-bkp {
        z-index: 1;
        height: auto;
        position: absolute;
        &, svg {
          height: responsiveSizeLegacyToV2(520);
          width: auto;
        }
      }
    }
  }
  .ks-content {
    margin-right: 0;
    background-color: #F2F2F2;
    border-radius: responsiveSizeLegacyToV2(18);
    width: var(--mainContentWidth);
    height: var(--mainContentHeight);
    overflow: hidden;
  }
}

.portrait {
  .hp-layoutV2 {
    flex-direction: column-reverse;
    height: var(--vmin);
  }
  .hp-leftArea {
    width: 100%;
    margin-top: 0;
    display: block;
  }
  .ks-content {
    margin-left: 0;
    margin-right: 0;
    margin-top: var(--headerHeight);
  }
}