@import '../../common.scss';

.hp-v3 {
  .ks-content {
    &.hp-homePage {
      background: none;
      background-color: #1C1D1E;
    }
    .ks-loginV3 {
      display: flex;
      height: auto;
      align-items: center;
      box-sizing: border-box;
      justify-content: center;
      background-color: white;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      width: responsiveSizeToV2(1020);
      height: responsiveSizeToV2(820);
      border-radius: responsiveSizeToV2(42);
      .ks-form-left {
        display: flex;
        flex-direction: column;
        width: responsiveSizeToV2(540);
        height: responsiveSizeToV2(654);
        .ks-form-title {
          margin-top: 0;
          color: #51AABC;
          font-family: 'Fredoka One';
          font-size: responsiveSizeToV2(32);
          margin-bottom: responsiveSizeToV2(30);
        }
        .ks-form {
          display: flex;
          flex: 1;
          flex-direction: column;
          justify-content: space-between;
          width: 92%;
          .ks-form-group {
            input.ks-form-control {
              border: none ;
              background-color: #F2F2F2;
            }
          }
        }
        .ks-formBtn {
          width: 92%;
          display: flex;
          justify-content: center;
          margin-top: responsiveSizeToV2(15);
          .mainBtn {
            font-weight: 900;
            font-family: 'Avenir';
            width: responsiveSizeToV2(226);
            height: responsiveSizeToV2(54);
            font-size: responsiveSizeToV2(26);
          }
        }
      }
      .ks-form-right {
        background-color: #51AABC;
        width: responsiveSizeToV2(360);
        height: responsiveSizeToV2(654);
        border-radius: responsiveSizeToV2(20);
      }
    }
  }
}