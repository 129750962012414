@import '../../common.scss';
.hp-eidgift {
  .hp-eidgift-card-loader {
    @include responsive-size(margin-top, 300);
    span {
      border: solid;
      border-color: white;
      border-bottom-color: transparent;
      @include responsive-size(height, 100);
      @include responsive-size(width, 100);
      @include responsive-size(border-width, 6);
    }
  }
  .hp-eidgift-card-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    .main-slider {
      @include responsive-size(margin-top, 100);
      @include responsive-size(height, 676);
      @include responsive-size(width, 941);
      @include media("<" + $min_mobile) { 
        @include responsive-size-mobile(margin-top, 169);
        @include responsive-size-mobile(width, 355);
        @include responsive-size-mobile(height, 255);
      }
    }
    .main-slider__arrow {
      z-index: 10;
      top: 55%;
      @include responsive-size(height, 180);
      @include responsive-size(width, 102);
      &.main-slider__arrow--next {
        right: 13%;
      }
      &.main-slider__arrow--prev {
        left: 13%;
      }
    }
    .hp-eidgift-card-download {
      @include responsive-size(margin-top, 16);
      @include responsive-size(width, 130.41);
      @include responsive-size(height, 116.1);
      @include media("<" + $min_mobile) { 
        @include responsive-size-mobile(margin-top, 12);
        @include responsive-size-mobile(width, 80);
        @include responsive-size-mobile(height, 78);
      }
    }
  }
  .hp-eidgift-card-empty {
    @include responsive-size(margin-top, 391);
    color: white;
    font-family: 'Avenir';
    text-align: center;
    @include responsive-size(font-size, 28);
    @include media("<" + $min_mobile) { 
      @include responsive-size-mobile(font-size, 14);
    }
    .hp-eidgift-card-empty-maintext {
      font-weight: 900;
    }
    .hp-eidgift-card-subtext {
      a {color: white;}
    }
  }
}