@import '../../common.scss';

.hp-signin-closeBtn {
  position: absolute;
  top: responsiveSizeLegacyToV2(300);
  right: responsiveSizeLegacyToV2(360);
  width: responsiveSizeLegacyToV2(70);
  height: responsiveSizeLegacyToV2(70);
}

.ks-content {
  &.hp-homePage {
    .ks-form {
      font-family: 'Avenir';
      @include responsive-size(font-size, 22);
      @include media("<480px") {
        // @include responsive-size-mobile(font-size, 22);
      }
      width: 84%;
      .ks-form-group {
        display: flex;
        flex-direction: column;
        @include responsive-size(margin-bottom, 20);
      }

      textarea,
      input {
        outline-style: none;
        box-shadow: none;
        border: 1px solid #C3C3C3;
        @include responsive-size(height, 72);
        @include responsive-size(margin-top, 5); 
        @include responsive-size(padding-left, 31); 
        @include responsive-size(border-radius, 10);
        &:focus {
          border: 1px solid #51AABC;
        }
      }
    }
    .ks-auth-signupBtn {
      font-family: 'Avenir';
      position: absolute;
      z-index: 10;
      font-weight: 900;
      @include responsive-size(font-size, 24);
      @include responsive-size(width, 210);
      @include responsive-size(height, 58);
      @include responsive-size(top, 13);
      @include responsive-size(right, 30);
      @include responsive-size(border-radius, 6); 
    }
    .ks-login {
      display: flex;
      height: auto;
      align-items: center;
      box-sizing: border-box;
      flex-direction: column;
      background-color: white;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      @include responsive-size(border-radius, 28); 
      @include responsive-size(width, 589);
      @include responsive-size(padding-top, 26);
      @include media("<480px") {
        // @include responsive-size-mobile(width, 921);
        // @include responsive-size-mobile(padding-top, 26);
        // @include responsive-size-mobile(border-radius, 42); 
      }
      .ks-loginTitle {
        font-family: 'Fredoka One';
        @include responsive-size(font-size, 35);
        @include responsive-size(margin-bottom, 30);
        @include media("<480px") {
          // @include responsive-size-mobile(font-size, 74);
          // @include responsive-size-mobile(margin-bottom, 104);
        }
      }
      .ks-formBtn {
        display: flex;
        flex-direction: column;
        .mainBtn {
          font-family: 'Avenir';
          font-weight: 700;
          display: flex;
          padding-bottom: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          @include responsive-size(height, 58);
          @include responsive-size(width, 240);
          @include responsive-size(font-size, 24); 
          @include media("<480px") {
            @include responsive-size(height, 122);
            @include responsive-size(width, 567);
            @include responsive-size(font-size, 46); 
          }
        }
        .ks-forgetBtn {
          border: none;
          background-color: transparent;
          font-family: 'Avenir';
          font-style: oblique;
          text-decoration: underline;
          @include responsive-size(font-size, 13); 
          @include responsive-size(margin-top, 13); 
        }
        @include responsive-size(margin-top, 37); 
        @include responsive-size(margin-bottom, 57); 
      }
    }
  }
}