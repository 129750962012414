@import '../../common.scss';

.hp-v3 {
  .ks-content {
    .hp-successfulPayment {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 'Avenir';
      flex-direction: column;
      svg {
        width: responsiveSizeToV2(290);
        height: responsiveSizeToV2(290);
      }
      &__info {
        color: #51AABC;
        font-size: responsiveSizeToV2(34);
        margin-top: responsiveSizeToV2(34);
        margin-bottom: responsiveSizeToV2(13);
      }
      &__btn {
        border: none;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #51AABC;
        height: responsiveSizeToV2(60);
        width: responsiveSizeToV2(396);
        font-size: responsiveSizeToV2(25);
        border-radius: responsiveSizeToV2(10);
        margin-bottom: responsiveSizeToV2(13);
      }
      &__thanks {
        color: black;
        font-size: responsiveSizeToV2(23);
      }
    }
  }
}