@import "./responsive";

$min_mobile: '1px';

@function responsiveSize($sizeInPixel, $ratio: 1) {
  $unit: vh;
  $result: $sizeInPixel / 1145 * 1 * 100 * $ratio;
  @if $ratio == 3/4 {
    $unit: vw;
  }
  @return $result + $unit;
}

@function responsiveSizeLegacyToV2($sizeInPixel, $ratio: 1) {
  $unit: px;
  @return calc(#{$sizeInPixel} / 1145 * var(--mainContentHeight));
}

@mixin responsive-size($attribute, $sizeInPixel: 0, $important: false) {
  & {
    @if $important {
      #{$attribute}: responsiveSize($sizeInPixel) !important;
    } @else {
      #{$attribute}: responsiveSize($sizeInPixel);
    }
  }
  @media (max-aspect-ratio: 4/3) {
    & {
      @if $important {
        #{$attribute}: responsiveSize($sizeInPixel, 3/4) !important;
      } @else {
        #{$attribute}: responsiveSize($sizeInPixel, 3/4);
      }
    }
  }
  @at-root {
    .hp-layoutV2 & {
      @if $important {
        #{$attribute}: responsiveSizeLegacyToV2($sizeInPixel) !important;
      } @else {
        #{$attribute}: responsiveSizeLegacyToV2($sizeInPixel);
      }
    }
  }
}

@function responsiveSizeMobile($sizeInPixel, $ratio: 1) {
	$unit: vw;
	$result: $sizeInPixel / 414 * 1 * 100 * $ratio;
	@return $result + $unit;
}

@mixin responsive-size-mobile($attribute, $sizeInPixel: 0) {
	& {
		#{$attribute}: responsiveSizeMobile($sizeInPixel);
	}
}


@function responsiveSizeToV2($sizeInPixel) {
  @return calc(#{$sizeInPixel} / 1038 * var(--mainContentHeight));
}

@font-face {
  font-family: 'KG Blank Space Solid';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/KGBlankSpaceSolid.ttf');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/Inter-VariableFont_slnt_wght.ttf');
}

@font-face {
  font-family: 'Fredoka One';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/FredokaOne-Regular.ttf');
}

@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 200;
  src: url('./assets/fonts/Avenir/Avenir-Light-07.ttf');
}

@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 300;
  src: url('./assets/fonts/Avenir/Avenir-Book-01.ttf');
}

@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/Avenir/Avenir-Medium-09.ttf');
}

@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/Avenir/Avenir-Heavy-05.ttf');
}

@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 900;
  src: url('./assets/fonts/Avenir/Avenir-Black-03.ttf');
}

@font-face {
  font-family: 'Minion Prod';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/Minion-Pro-Medium.ttf');
}

@font-face {
  font-family: 'Itim';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/Itim-Regular.ttf');
}

@font-face {
  font-family: 'BahijMyriadArabic-Bold';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/Bahij_Myriad_Arabic-Bold.ttf');
}

@font-face {
  font-family: 'Bahij Myriad Arabic';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/Bahij_Myriad_Arabic-Regular.ttf');
}

@font-face {
  font-family: 'Fedra Sans Arabic AR';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/FedraSansArabicAR-Medium.otf');
}