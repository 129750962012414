@import '../../common.scss';

.hp-v3 {
  .ks-avatar-selector {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    &__title {
      color: white;
      font-weight: 700;
      font-family: 'Avenir';
      font-size: responsiveSizeToV2(21);
      margin-top: responsiveSizeToV2(34);
      margin-bottom: responsiveSizeToV2(12);
    }
    &__gender {
      margin-bottom: responsiveSizeToV2(17);
      svg {
        cursor: pointer;
        width: responsiveSizeToV2(64);
        height: responsiveSizeToV2(64);
        &:not(:last-child) {
          margin-right: responsiveSizeToV2(16);
        }
        &.selected {
          g rect {
            fill: white;
            stroke: white;
          }
          cursor: default;
        }
      }
    }
    &__list {
      display: flex;
      flex-wrap: wrap;
      width: responsiveSizeToV2(328);
      height: responsiveSizeToV2(493);  
      overflow-x: hidden;
      overflow-y: scroll;
      scrollbar-width: none;
      -ms-overflow-style: none;
      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }
      svg {
        width: responsiveSizeToV2(75);
        height: responsiveSizeToV2(75);
        margin-bottom: responsiveSizeToV2(17);
        &:not(:nth-child(4n)) {
          margin-right: responsiveSizeToV2(9);
        }
        opacity: 0.3;
        cursor: pointer;
        &.selected {
          opacity: 1;
          cursor: default;
        }
      }
    }
  }
}