@import '../../common.scss';

body.embeddedBody {
  background-color: transparent;
  .ks-embedded {
    width: 100%;
    .hp-topBanner {display: none;}
    &.hp-v3 .ks-content {
      background-color: transparent;
      width: 100%;
      display: flex;
      border-radius: 0;
      justify-content: center;
      align-items: center;
      .ks-loginV3 {box-shadow: none; }
    }
  }
}