@import '../../common.scss';

@function responsiveMenuSize($sizeInPixel) {
  @return calc(#{$sizeInPixel} * var(--headerHeight) / 85);
}

.hp-layoutV2 {
  .hp-feed-frame-content {
    position: absolute;
    height: 0;
    width:  calc(var(--vw) - var(--leftAreaMinWidth));
    background-color: rgba(0, 0, 0, 0.9);
    top:0;
    left: calc(var(--leftAreaMinWidth) - 1%);
    z-index: 10;
    transition: height 0.5s ease-in-out;
    overflow: hidden;

    .hp-feed-frame-third-column {
      height: 100%;
      margin-left: responsiveMenuSize(30);
      .hp-feed-closeBtn {
        margin-top: responsiveMenuSize(50);
        width: responsiveMenuSize(90);
        height: responsiveMenuSize(90);
      }
    }
    &.open {
      height: calc(var(--mainContentHeight) + 0.5%);
    }
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    .hp-feed-frame-content-media{
      .hp-imageLoader {
        width: 100%; 
        height: 100%;
        justify-content: center;
        align-items: center;
        position: relative;
        span {
          border-color: white;
          border-bottom-color: transparent;
          width: responsiveMenuSize(60);
          height: responsiveMenuSize(60);
        }
      }
      &, img, iframe {
        border-radius: responsiveMenuSize(33);
        width: responsiveMenuSize(681);
        height: responsiveMenuSize(879);
        border: none;
      }
    }
    .hp-feed-frame-content-description {
      margin-left: responsiveMenuSize(61);
      width: responsiveMenuSize(425);
      height: responsiveMenuSize(879);
      .hp-feed-frame-content-title{
        .hp-feed-frame-content-title-arabic {
          font-family: 'Myriad Arabic';
          font-size: responsiveMenuSize(88);
          font-weight: bold;
          color: #C3C3C3;
        }
        .hp-feed-frame-content-title-latin{
          font-family: 'Fredoka One';
          font-size: responsiveMenuSize(31);
          display: flex;
          align-items: center;
          svg {
            margin-left: responsiveMenuSize(17);
            width: responsiveMenuSize(75);
            height: responsiveMenuSize(75);
          }
        }
      }
      .hp-feed-frame-content-text {
        color: #C3C3C3;
        font-size: responsiveMenuSize(22);
        max-height: responsiveMenuSize(700);
        padding-right: responsiveMenuSize(30);
        overflow-y: auto;
        b {
          font-weight: 900;
        }
        &::-webkit-scrollbar-track
        {
          -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
          background-color: #F5F5F5;
        }

        &::-webkit-scrollbar
        {
          width: 6px;
          background-color: #F5F5F5;
        }

        &::-webkit-scrollbar-thumb
        {
          background-color: #707070;
        }
      }
      .hp-feed-frame-content-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: responsiveMenuSize(40);
        svg {
          display: none;
        }
        .pagination {
          font-family: 'Fredoka One';
          font-size: responsiveMenuSize(24);
          margin-left: responsiveMenuSize(20);
          margin-right: responsiveMenuSize(20);
        }
        .mainBtn {
          color: black;
          font-weight: 900;
          font-family: 'Avenir';
          background-color: #F2F2F2;
          font-size: responsiveMenuSize(19);
          width: responsiveMenuSize(176);
        }
      }
    }
  }
  .hp-feed-frame {
    height: fit-content;
    margin-left: auto;
    margin-right: auto;
    color: #C3C3C3;
    text-align: center;
    font-family: 'Avenir';
    line-height: 1.1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: var(--leftAreaMinWidth);
    margin-top: var(--headerHeight);

    .hp-feed-primary,
    .hp-feed-secondary {
      width: 80%;
      border: responsiveMenuSize(2) solid #707070;
      border-radius: responsiveMenuSize(15);
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 4% 4%;
      box-sizing: border-box;
      margin: auto;
      &.hp-v3 {
        margin-top: responsiveSizeToV2(470);
      }
      &-title {
        font-size: responsiveMenuSize(17);
      }
      img {
        width: 100%;
        height: auto;
        border-radius: responsiveMenuSize(15);
        padding: 3%;
        box-sizing: border-box;
      }
      .hp-imageLoader {
        width: 70%; 
        padding-top: 70%;
        justify-content: center;
        align-items: center;
        border-color: 'white';
        position: relative;
        span {
          border-color: white;
          border-bottom-color: transparent;
          position: absolute;
          top: 35%;
          width: 20%;
          height: 20%;
        }
      }
      .mainBtn {
        font-family: 'Avenir';
        color: black;
        font-weight: 900;
        background-color: #F2F2F2;
        width: 50%;
        margin-top: 4%;
        margin-bottom: 0.5%;
        font-size: responsiveMenuSize(19);
      }
    }
    .hp-feed-primary {
      margin-bottom: 7%;
      font-size: responsiveMenuSize(19);
      .hp-feed-primary-maintitle {
        font-size: responsiveMenuSize(23);
        font-family: 'Fredoka One';
        margin-top: 2%;
        margin-bottom: 2%;
      }
    }
  }
}

.portrait {
  .hp-layoutV2 {
    .hp-feed-frame {
      display: flex;
      justify-content: center;
      margin: 0 auto;
      width: var(--mainContentWidth);
      .hp-feed-primary,
      .hp-feed-secondary {
        border: none;
        margin: 0;
        padding: 0;
        margin-top: 3%;
        width: 50%;
        position: relative;
        &-title {
          font-size: responsiveMenuSize(22);
        }
        img {
          width: 96%;
          max-width: calc(var(--finalLeftAreaHeight) * 0.76);
          margin-top: 2%;
          height: auto;
          padding: 0;
        }
        .mainBtn {
          font-size: responsiveMenuSize(21);
          border-radius: responsiveMenuSize(4);
          width: 70%;
          max-width: calc(var(--finalLeftAreaHeight) * 0.6);
          margin-top: 2%;
          margin-bottom: 0.5%;
          position: absolute;
          bottom: responsiveMenuSize(-19);
        }
      }
      .hp-feed-primary {
        margin-bottom: 0;
        font-size: responsiveMenuSize(19);
        .hp-feed-primary-text,
        .hp-feed-primary-maintitle {
          display: none;
        }
      }
    }

    .hp-feed-frame-content {
      position: absolute;
      background-color: black;
      &.open {
        height: calc(var(--vh));
      }
      width:  var(--vw);
      left: 0;
      flex-direction: column;
      .hp-feed-frame-content-media {
        margin-top: 2%;
        &, img, iframe {
          width: var(--mainContentWidth);
          height: var(--mainContentHeight);
        }
      }
      .hp-feed-frame-content-description {
        margin-left: 0;
        width: var(--vw);
        flex: 1;
        height: responsiveMenuSize(30);
        position: relative;
        .hp-feed-frame-content-title{
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 1%;
          .hp-feed-frame-content-title-latin{
            margin-left: responsiveMenuSize(10);
            font-size: responsiveMenuSize(31);
          }
        }
        .hp-feed-frame-content-text {
          margin: 0 3%;
          height: 60%;
          font-size: responsiveSizeToV2(40);
          overflow-y: auto;
        }

        .hp-feed-frame-content-btn {
          margin-top: 3%;
          .mainBtn {
            height: responsiveMenuSize(55);
            border-radius: responsiveMenuSize(28);
            font-size: responsiveMenuSize(26);
            width: responsiveMenuSize(220);
          }
        }
      }
      
      .hp-feed-frame-third-column {
        position: absolute;
        top: 0;
        right: 0;
        margin: 0;
        .hp-feed-closeBtn {
          width: responsiveMenuSize(70);
          height: responsiveMenuSize(70);
          margin-top: responsiveMenuSize(10);
          margin-right: responsiveMenuSize(10);
          border-radius: 100%;
        }
      }
    }
  }
}