@import '../../common.scss';


.checkbox {
  display: grid;
  cursor: pointer;
  grid-template-columns: min-content auto;
  grid-gap: responsiveSizeToV2(5);
  font-size: responsiveSizeToV2(18);
  color: var(--color);
  width: fit-content;
  &--disabled {
    color: var(--disabled);
  }
}

.checkbox__control {    
  display: flex;
  align-items: center;
  justify-content: center;
  width: responsiveSizeToV2(24);
  height: responsiveSizeToV2(24);
  border-radius: responsiveSizeToV2(5);
  border: responsiveSizeToV2(2) solid #CCD1D3;

  svg {
    transition: transform 0.1s ease-in 25ms;
    transform: scale(0);
    transform-origin: bottom left;
  }
}

.checkbox__input {
  display: grid;
  grid-template-areas: "checkbox";
  &, input { cursor: pointer; }
  > * {
    grid-area: checkbox;
  }

  input {
    opacity: 0;
    width: responsiveSizeToV2(24);
    height: responsiveSizeToV2(24);

    &:focus + .checkbox__control {
      box-shadow: 0 0 0 0.05em #fff, 0 0 0.15em 0.1em currentColor;
    }

    &:checked + .checkbox__control svg {
      transform: scale(1);
    }

    &:disabled + .checkbox__control {
      color: var(--disabled);
    }
  }
}